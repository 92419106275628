import React from 'react'
import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { css } from 'emotion'

import Page from 'layouts/Page'
import CodeBlock from 'components/CodeBlock'

const container = css`
    --space: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > * {
        margin-top: 0;
        margin-bottom: 0;
    }

    & > * + * {
        margin-top: var(--space);
    }
`

const components = {
    pre: props => <div {...props} />,
    code: CodeBlock,
}

function Post({
    data: {
        mdx: { frontmatter, body },
    },
}) {
    return (
        <Page boxed={true} title={frontmatter.title}>
            <h2>{frontmatter.title}</h2>
            <h4>{frontmatter.date}</h4>
            <Link to="/blog"> back to blog</Link>
            <MDXRenderer components={components}>{body}</MDXRenderer>
        </Page>
    )
}

export default Post

export const pageQuery = graphql`
    query($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            id
            body
            frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
            }
        }
    }
`
